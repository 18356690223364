import React, { useState } from "react";
import styled from "styled-components";
import Search from "../Functional/Search";
import DetectButton from "../Functional/DetectButton";

const HeaderWrapper = styled.div`
  height: 8vh;
  min-height: 60px;
  width: 100vw; 
  // padding-left: 16px;
  background-color: #183883;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
`;

const Or = styled.div`
  font-size: 14px;
  color: white;
  margin: 16px;
`;

const Header = (props) => {

  const [value, setValue] = useState(null);


  let accessToken = props.accessToken;
  let setDealerList = props.setDealerList;
  let configData = props.configData;
  let setSearchedPlace = props.setSearchedPlace;
  let setUserLatlng = props.setUserLatlng;
  let language = props.language;
  let isMobile = props.isMobile;

  return (
    <HeaderWrapper>
      <Search
        accessToken={accessToken}
        setDealerList={setDealerList}
        configData={configData}
        setSearchedPlace={setSearchedPlace}
        setUserLatlng={setUserLatlng}
        language={language}
        setValue={setValue}
        value={value}
      />
      <Or>{language.OR}</Or>
      <DetectButton
        accessToken={accessToken}
        setDealerList={setDealerList}
        configData={configData}
        setSearchedPlace={setSearchedPlace}
        setUserLatlng={setUserLatlng}
        language={language}
        isMobile={isMobile}
        setValue={setValue}
      />
    </HeaderWrapper>
  )
}

export default Header;