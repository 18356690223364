import React, { useEffect, useState } from "react";
import { TextField, Autocomplete, createFilterOptions, Dialog, DialogTitle, IconButton } from "@mui/material";
import styled from "styled-components";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
// import { trackGoogleAnalyticsEvent } from '../../MainComponents/GoogleAnalytics';

const filter = createFilterOptions();

const handleDealerSearch = (searchTerm, GA_MEASUREMENT_ID) => {
  // Create the script element
  const script = document.createElement('script');
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`;

  // Append the script to the head of the document
  document.head.appendChild(script);

  // Set up the 'onload' callback for the script
  script.onload = () => {
    // Define the gtag initialization function
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }

    // Initialize Google Analytics
    gtag('js', new Date());
    gtag('config', GA_MEASUREMENT_ID);

    // Check if the searchTerm is different from the previously tracked search term
    if (searchTerm !== window.lastTrackedSearchTerm) {
      // Now, you can use the gtag function to send the GA4 event
      gtag('event', 'dealer_search', {
        'section_name': 'Dealers Near You',
        'search_term': searchTerm,  // Replace with actual user input
        'cta_text': 'Search'     // Replace with the text of the button clicked
      });

      // Update the last tracked search term
      window.lastTrackedSearchTerm = searchTerm;
    }
  };

  // Clean up by removing the script when the component unmounts
  return () => {
    document.head.removeChild(script);
  };
};

const SearchInput = styled(TextField)(() => ({
  backgroundColor: '#ffffff',
  width: '100%',
  borderRadius: '4px',
  fontSize: '14px !important'
}));

const SearchAutocomplete = styled(Autocomplete)(() => ({
  minWidth: '28%',
  width: 'auto',
  borderRadius: '8px',
  marginLeft: '16px',
}));

const StyledListItem = styled.li`
  border-bottom: 1px solid silver;
  padding: 10px;
  
  &:last-child {
    border-bottom: none;
  }
`;

const PopupContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f8f8f8;
  padding: 16px;
  border-radius: 4px;
  margin-bottom: 16px;
`;

const PopupText = styled.p`
  margin: 0;
`;

const Search = (props) => {
  let value = props.value;
  let setValue = props.setValue;
  const [autocompleteInputs, setAutocompleteInputs] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [searchTerm, setSearchTerm] = useState(''); // Current search term in the search bar
  const accessToken = props.accessToken;
  const setDealerList = props.setDealerList;
  const configData = props.configData;
  const setSearchedPlace = props.setSearchedPlace;
  const setUserLatlng = props.setUserLatlng;
  const language = props.language;
  // console.log(configData.google_analytics_id)

  const API_ENDPOINT = 'https://ib_api.latlong.in/';

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClosePopup}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const autocomplete = (newValue) => {
    if (newValue.length > 2) {
      // Autocomplete API request
      axios({
        method: 'GET',
        url: `https://ib_api.latlong.in/autocomplete?country=${configData.country_code}&limit=5&query=${newValue}&access_token=${accessToken}`
      }).then((response) => {
        setAutocompleteInputs(response.data.data);
      }).catch((error) => {
        // console.error(error);
      });
    }
  };

  useEffect(() => {
    if (value && value.name !== "No options" && accessToken) {
      setSearchedPlace(value.name);
      axios({
        method: 'GET',
        url: `https://ib_api.latlong.in/brands/${configData.brand_id}/search?lat=${value.coordinates.latitude}&long=${value.coordinates.longitude}&country=${configData.country_code}&lang=${configData.wh_lang}&category_id=${configData.category_id}&access_token=${accessToken}`
      }).then((response) => {
        setDealerList(response.data.stores);
        setUserLatlng([response.data.lr.coordinates.latitude, response.data.lr.coordinates.longitude]);
      }).catch((error) => {
        // console.error(error);
      });
    } else {
      setValue(null);
    }
  }, [value]);

  useEffect(() => {
    if (value && value.name) {
      // Send the GA4 event with the selected value
      handleDealerSearch(value.name, configData.google_analytics_id);
    }
  }, [value, configData.google_analytics_id]);


  const handleAutocompleteChange = (event, newValue) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (!newValue || !newValue.name) {
        setShowPopup(true);
      } else {
        setValue(newValue); // Set the selected value
      }
    } else {
      if (typeof newValue === 'string') {
        setSearchTerm(newValue); // Update the search term in the search bar
      } else {
        setValue(newValue); // Set the selected value
      }
    }
  };

  return (
    <>
      <SearchAutocomplete
        value={value}
        {...(() => {
          if (value !== null) {
            handleDealerSearch(value.name, configData.google_analytics_id);
          }
        })()}
        onInputChange={(e, val, reason) => {
          autocomplete(val);
        }}
        onChange={handleAutocompleteChange}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          if (params.inputValue == '' || options == null) {
            filtered.push({
              name: "No options",
            });
          }
          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        size='small'
        id="free-solo-with-text-demo"
        options={autocompleteInputs}
        getOptionLabel={(option) => option.name || ''}
        renderOption={(props, option) =>
          <StyledListItem {...props}>
            {option.name ? option.name : 'No options'}
          </StyledListItem>
        }
        noOptionsText='No options'
        // disableClearable
        sx={{ width: 300 }}
        freeSolo
        renderInput={(params) => (
          <SearchInput
            color={'primary'}
            variant={'filled'}
            {...params}
            label={language.SearchPlaceholder}
            style={{ fontSize: '14px' }}
          />
        )}
      />
      {/* <Dialog open={showPopup} onClose={handleClosePopup}>
        <PopupContainer>
          <PopupText>Please select an option from the dropdown</PopupText>
          <IconButton edge="end" onClick={handleClosePopup}>
            <CloseIcon />
          </IconButton>
        </PopupContainer>
      </Dialog> */}
      <Snackbar
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        open={showPopup}
        autoHideDuration={6000}
        onClose={handleClosePopup}
      >
        <Alert severity="info" action={action}>Please select from dropdown options.</Alert>
      </Snackbar>
    </>
  );
};

export default Search;
