import React from 'react';
import './App.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import TvsMexico from './Pages/tvs_mexico';
import TvsGuatemala from './Pages/tvs_guatemala';
import TvsNepal from './Pages/tvs_nepal';
import TvsPanama from './Pages/tvs_panama';
import TvsPhilippines from './Pages/tvs_philippines';
import TvsSingapore from './Pages/tvs_singapore';
import TvsHonduras from './Pages/tvs_honduras';
import TvsColombia from './Pages/tvs_colombia';
import TvsNigeria from './Pages/tvs_nigeria';
import TvsVenezuela from './Pages/tvs_venezuela';
import TvsIndonesia from './Pages/tvs_indonesia';
import TvsPeruAll from './Pages/tvs_peru_all';
import TvsPeruTwoWheeler from './Pages/tvs_peru_two_wheeler';
import TvsPeruThreeWheeler from './Pages/tvs_peru_three_wheeler';
import TvsElsalvador from './Pages/tvs_el_salvador';
import TvsChile from './Pages/tvs_chile';
import TvsNicaragua from './Pages/tvs_nicaragua';
import TvsCostarica from './Pages/tvs_costarica';
import TvsEcuador from './Pages/tvs_ecuador';
import TvsBolivia from './Pages/tvs_bolivia';
import TvsDominicanrepublic from './Pages/tvs_dominicrepublic.';
import TvsHaiti from './Pages/tvs_haiti';
import TvsParaguay from './Pages/tvs_paraguay';
import TvsUruguay from './Pages/tvs_uruguay';
import TvsItaly from './Pages/tvs_italy';
import AllCountries from './Pages/all_countries';
import ErrorPage from './Pages/ErrorPage';
import Dashboard from './Pages/Dashboard';
import MexicoConfig from './Data/MexicoConfig.json';
import GuatemalaConfig from './Data/GuatemalaConfig.json';
import NepalConfig from './Data/NepalConfig.json';
import PanamaConfig from './Data/PanamaConfig.json';
import PhilippinesConfig from './Data/PhilippinesConfig.json';
import SingaporeConfig from './Data/Singapore.json';
import HondurasConfig from './Data/HondurasConfig.json';
import ColombiaConfig from './Data/ColombiaConfig.json';
import NigeriaConfig from './Data/NigeriaConfig.json';
import VenezuelaConfig from './Data/VenezuelaConfig.json';
import IndonesiaConfig from './Data/IndonesiaConfig.json';
import PeruAllConfig from './Data/PeruAllConfig.json';
import PeruTwoWheelerConfig from './Data/PeruTwoWheelerConfig.json';
import PeruThreeWheelerConfig from './Data/PeruThreeWheelerConfig.json';
import ElSalvadorConfig from './Data/ElSalvaodorConfig.json';
import ChileConfig from './Data/ChileConfig.json';
import NicaraguaConfig from './Data/NicaraguaConfig.json';
import CostaricaConfig from './Data/CostaricaConfig.json';
import EcuadorConfig from './Data/EcuadorConfig.json';
import BoliviaConfig from './Data/BoliviaConfig.json';
import DominicanrepublicConfig from './Data/DominicanrepublicConfig.json';
import HaitiConfig from './Data/HaitiConfig.json';
import ParaguayConfig from './Data/ParaguayConfig.json';
import UruguayConfig from './Data/UruguayConfig.json';
import ItalyConfig from './Data/ItalyConfig.json';
import ArgentinaConfig from './Data/ArgentinaConfig.json';

const router = createBrowserRouter([
  {
    path: '/', element: <Dashboard />, errorElement: <ErrorPage />
  },
  {
    path: '/tvs_mexico/:lang', element: <AllCountries config={MexicoConfig} />, errorElement: <ErrorPage />
  },
  {
    path: '/tvs_guatemala/:lang', element: <AllCountries config={GuatemalaConfig} />, errorElement: <ErrorPage />
  },
  {
    path: '/tvs_nepal/:lang', element: <AllCountries config={NepalConfig} />, errorElement: <ErrorPage />
  },
  {
    path: '/tvs_panama/:lang', element: <AllCountries config={PanamaConfig} />, errorElement: <ErrorPage />
  },
  {
    path: '/tvs_philippines/:lang', element: <AllCountries config={PhilippinesConfig} />, errorElement: <ErrorPage />
  },
  {
    path: '/tvs_singapore/:lang', element: <AllCountries config={SingaporeConfig} />, errorElement: <ErrorPage />
  },
  {
    path: '/tvs_honduras/:lang', element: <AllCountries config={HondurasConfig} />, errorElement: <ErrorPage />
  },
  {
    path: '/tvs_colombia/:lang', element: <AllCountries config={ColombiaConfig} />, errorElement: <ErrorPage />
  },
  {
    path: '/tvs_nigeria/:lang', element: <AllCountries config={NigeriaConfig} />, errorElement: <ErrorPage />
  },
  {
    path: '/tvs_venezuela/:lang', element: <AllCountries config={VenezuelaConfig} />, errorElement: <ErrorPage />
  },
  {
    path: '/tvs_indonesia/:lang', element: <AllCountries config={IndonesiaConfig} />, errorElement: <ErrorPage />
  },
  {
    path: '/tvs_peru/:lang', element: <AllCountries config={PeruAllConfig} />, errorElement: <ErrorPage />
  },
  {
    path: '/tvs_peru/2w/:lang', element: <AllCountries config={PeruTwoWheelerConfig} />, errorElement: <ErrorPage />
  },
  {
    path: '/tvs_peru/3w/:lang', element: <AllCountries config={PeruThreeWheelerConfig} />, errorElement: <ErrorPage />
  },
  {
    path: '/tvs_el_salvador/:lang', element: <AllCountries config={ElSalvadorConfig} />, errorElement: <ErrorPage />
  },
  {
    path: '/tvs_chile/:lang', element: <AllCountries config={ChileConfig} />, errorElement: <ErrorPage />
  },
  {
    path: '/tvs_nicaragua/:lang', element: <AllCountries config={NicaraguaConfig} />, errorElement: <ErrorPage />
  },
  {
    path: '/tvs_costarica/:lang', element: <AllCountries config={CostaricaConfig} />, errorElement: <ErrorPage />
  },
  {
    path: '/tvs_ecuador/:lang', element: <AllCountries config={EcuadorConfig} />, errorElement: <ErrorPage />
  },
  {
    path: '/tvs_bolivia/:lang', element: <AllCountries config={BoliviaConfig} />, errorElement: <ErrorPage />
  },
  {
    path: '/tvs_dominican_republic/:lang', element: <AllCountries config={DominicanrepublicConfig} />, errorElement: <ErrorPage />
  },
  {
    path: '/tvs_haiti/:lang', element: <AllCountries config={HaitiConfig} />, errorElement: <ErrorPage />
  },
  {
    path: '/tvs_paraguay/:lang', element: <AllCountries config={ParaguayConfig} />, errorElement: <ErrorPage />
  },
  {
    path: '/tvs_uruguay/:lang', element: <AllCountries config={UruguayConfig} />, errorElement: <ErrorPage />
  },
  {
    path: '/tvs_italy/:lang', element: <AllCountries config={ItalyConfig} />, errorElement: <ErrorPage />
  },
  {
    path: '/tvs_argentina/:lang', element: <AllCountries config={ArgentinaConfig} />, errorElement: <ErrorPage />
  }
])

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
