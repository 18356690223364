import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import MyLocationIcon from '@mui/icons-material/MyLocation';
import axios from "axios";
import styled from "styled-components";
import { createFilterOptions } from "@mui/material";
// import { trackGoogleAnalyticsEvent } from "../../MainComponents/GoogleAnalytics";

const filter = createFilterOptions();
// const GA_MEASUREMENT_ID = 'G-K37JN0V35J';

const handleDealerSearch = (searchTerm, GA_MEASUREMENT_ID) => {
  // console.log("Detect")
  // trackGoogleAnalyticsEvent('Detect', 'dealer_search', 'Dealers Near You');
  // Create the script element
  const script = document.createElement('script');
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`;

  // Append the script to the head of the document
  document.head.appendChild(script);

  // Set up the 'onload' callback for the script
  script.onload = () => {
    // Define the gtag initialization function
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }

    // Initialize Google Analytics
    gtag('js', new Date());
    gtag('config', GA_MEASUREMENT_ID);

    // Now, you can use the gtag function
    document.getElementById('latlong_detect_button_contents').addEventListener('click', function() {
      gtag('event', 'dealer_search', {
        'section_name': 'Dealers Near You',
        'search_term': searchTerm,  // Replace with actual user input
        'cta_text': 'Detect'     // Replace with the text of the button clicked
      });
    });
  };

  // Clean up by removing the script when the component unmounts
  return () => {
    document.head.removeChild(script);
    };
};

const StyledSpan = styled.span`
  width: fit-content;
  display: flex;
  align-items: center;
  padding-left: 8px;
  
  @media (max-width: 768px) {
    &::after {
      content: '';
      font-size: 14px;
    }
  }
  @media (min-width: 768px) {
    &::after {
      // content: 'Detect';
      font-size: 14px;
    }
  }
`;

const StyledDetectBtn = styled(Button)`
  && {
    margin-right: 16px;
    height: 40px;
  }  
`;

var options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};

function errors(err) {
  console.warn(`ERROR(${err.code}): ${err.message}`);
}

const DetectButton = (props) => {

  const [detectLat, setdetectLat] = useState();
  const [detectlng, setdetectLng] = useState();
  const [eventRegistered, setEventRegistered] = useState(false);

  let accessToken = props.accessToken;
  let setDealerList = props.setDealerList;
  let configData = props.configData;
  let setSearchedPlace = props.setSearchedPlace;
  let setUserLatlng = props.setUserLatlng;
  let language = props.language;
  let isMobile = props.isMobile;
  let setValue = props.setValue;

  const handleClick = (e) => {
    if (!eventRegistered) {
      setEventRegistered(true);
      setValue(null);
      if (navigator.geolocation) {
        navigator.permissions
          .query({ name: "geolocation" })
          .then(function (result) {
            if (result.state === "granted") {
              navigator.geolocation.getCurrentPosition(success);
            } else if (result.state === "prompt") {
              navigator.geolocation.getCurrentPosition(success, errors, options);
            } else if (result.state === "denied") {
              // Handle denied state
            }
            result.onchange = function () {};
          });
      } else {
        alert("Geolocation is not available.");
      }
    }
  }


  function success(pos) {
    let geo_latitude = pos.coords.latitude;
    let geo_longitude = pos.coords.longitude;
    handleDealerSearch(`${geo_latitude},${geo_longitude}`, configData.google_analytics_id)
    setdetectLat(geo_latitude);
    setdetectLng(geo_longitude);
    setUserLatlng([geo_latitude, geo_longitude])
    axios({
      method: 'GET',
      url: `https://ib_api.latlong.in/brands/${configData.brand_id}/search?lat=${geo_latitude}&long=${geo_longitude}&country=${configData.country_code}&lang=${configData.wh_lang}&category_id=${configData.category_id}&access_token=${accessToken}`
    }).then((response) => {
      // setAutocompleteInputs(response.data.data);
      setDealerList(response.data.stores);
      setSearchedPlace(response.data.lr.name)
    }).catch((error) => {
      // console.log(error);
    })
  }


  return (
    <div className="latlong_detect_button_contents" id="latlong_detect_button_contents">
      <StyledDetectBtn
        variant="outlined"
        onClick={handleClick}
        style={{ backgroundColor: 'white' }}
      >
        <MyLocationIcon />
        <StyledSpan>{!isMobile && language.Detect}</StyledSpan>
      </StyledDetectBtn>
    </div>
  )
}

export default DetectButton;