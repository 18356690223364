import React from "react";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import DealerCard from "./DealerCard";
import styled from "styled-components";

const StyledBox = styled(Box)`
  height: ${props => {
    return props.categoryless_required === 'true' ? '75vh' : '80vh';
  }};
  overflow: auto;
  &::-webkit-scrollbar-thumb {
    background-color: red !important;
  }
`;

const DealerStack = (props) => {

  let dealerList = props.dealerList;
  let setDealerCardSelected = props.setDealerCardSelected;
  let setUserLatlng = props.setUserLatlng;
  let userLatlng = props.userLatlng;
  let two_letter_country_code = props.two_letter_country_code;
  let setdealercardLatlong = props.setdealercardLatlong;
  let language = props.language;
  let sendsms_required = props.sendsms_required;
  let configData = props.configData;
  let categoryless_required = props.categoryless_required;

  return (
    <StyledBox className="dealerContentDiv" style={{height: categoryless_required === 'true' ? '75vh' : '80vh'}}>
      <Stack
        direction='column'
        spacing={1}
      >
        {dealerList && dealerList.map((item) =>

        (item.coordinates.latitude &&
          <DealerCard key={item.id} dealerList={item} configData={configData} two_letter_country_code={two_letter_country_code} setDealerCardSelected={setDealerCardSelected} setUserLatlng={setUserLatlng} userLatlng={userLatlng} setdealercardLatlong={setdealercardLatlong} language={language} sendsms_required={sendsms_required}/>
        )
        )}
      </Stack>
    </StyledBox>
  );
}

export default DealerStack;