import React from "react";
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import ElectricRickshawIcon from '@mui/icons-material/ElectricRickshaw';
import Button from '@mui/material/Button';
import styled from "styled-components";

const StyledDealerBtn = styled(Button)`
  margin-right: 4px !important;
  height: 30px;
  padding: 6px;
  border-radius: 4px;
`;

const DealerButtons = (props) => {

  let setTwoWheeler = props.setTwoWheeler;
  let setThreeWheeler = props.setThreeWheeler;
  let twoWheeler = props.twoWheeler;
  let threeWheeler = props.threeWheeler;
  let language = props.language;
  let twoWheelerCategoryPresent = props.twoWheelerCategoryPresent;
  let threeWheelerCategoryPresent = props.threeWheelerCategoryPresent;

  const dealerTypeSelected = (dealerType) => {
    if (dealerType === '2w') {
      setTwoWheeler(true);
      setThreeWheeler(false);
    } else if (dealerType === '3w') {
      setTwoWheeler(false);
      setThreeWheeler(true);
    }
  }

  if(twoWheelerCategoryPresent.toLowerCase() == 'true' && threeWheelerCategoryPresent.toLowerCase() == 'true'){
    return (
      <>
        <StyledDealerBtn className="" variant={twoWheeler ? "contained" : "outlined"} size={'small'} onClick={() => dealerTypeSelected("2w")}>
          <TwoWheelerIcon style={{ marginRight: '4px' }}></TwoWheelerIcon>
          {language.TwoWheeler}
        </StyledDealerBtn>
        <StyledDealerBtn size={'small'} className="" variant={threeWheeler ? "contained" : "outlined"} onClick={() => dealerTypeSelected("3w")}>
          <ElectricRickshawIcon style={{ marginRight: '4px' }}></ElectricRickshawIcon>
          {language.ThreeWheeler}
        </StyledDealerBtn>
      </>
    )
  }
  else if (twoWheelerCategoryPresent.toLowerCase()=='true'){
    return (
      <>
        <StyledDealerBtn className="" variant={twoWheeler ? "contained" : "outlined"} size={'small'} onClick={() => dealerTypeSelected("2w")}>
          <TwoWheelerIcon style={{ marginRight: '4px' }}></TwoWheelerIcon>
          {language.TwoWheeler}
        </StyledDealerBtn>
      </>
    )
  }
  else if(threeWheelerCategoryPresent.toLowerCase()=='true'){
    return (
      <>
      <StyledDealerBtn size={'small'} className="" variant={threeWheeler ? "contained" : "outlined"} onClick={() => dealerTypeSelected("3w")}>
          <ElectricRickshawIcon style={{ marginRight: '4px' }}></ElectricRickshawIcon>
          {language.ThreeWheeler}
        </StyledDealerBtn>
      </>
    )
  }
}

export default DealerButtons;