import { useRouteError } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { Button } from "@mui/material";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div id="error-page" style={{ display: 'flex', flexDirection: 'column', margin: 'auto', alignItems: 'center' }}>
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      {/* <p>
        <i>{error.statusText || error.message}</i>
      </p> */}
      {/* <Button variant="contained">
        <NavLink style={{ textDecoration: 'none', color: 'white' }} to="/">Dashboard</NavLink>
      </Button> */}
    </div>
  );
}