import React, { useRef } from "react";
import './Map.css';
import { MapContainer, TileLayer, Marker, Popup, useMap, ZoomControl } from 'react-leaflet';
import { useState, useEffect } from 'react';
import { Icon } from "leaflet";
import L from 'leaflet';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import DirectionsIcon from '@mui/icons-material/Directions';
import Divider from '@mui/material/Divider';
import styled from "styled-components";
import { v4 as uuidv4 } from 'uuid';
import directionIcon from './../../Assets/directions.svg';
// import MarkerClusterGroup from "react-leaflet-markercluster";
import MarkerClusterGroup from 'react-leaflet-cluster'
import { useParams, useResolvedPath } from "react-router-dom";
import { renderToStaticMarkup } from 'react-dom/server';


const skater = new Icon({
  iconUrl: "/home/omprakash/workspace/react_osl_ib/src/logo.svg",
  iconSize: [25, 25],
  Class: "leaflet-marker-icon location-pin leaflet-zoom-animated leaflet-interactive"
});

const bullsEyeIcon = L.divIcon({
  className: 'bulls-eye-icon',
  iconSize: [30, 30],
  html: '<div class="bulls-eye-ring"></div><div class="bulls-eye-dot"></div>'
});

const PopupItems = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin: 1em 0;
  whiteSpace: 'normal';
  wordWrap: 'break-word';
  overflowWrap: 'break-word'
`;

const PopupIcons = styled.div`
  width: auto;
  hieght: auto;
  padding:4px;
  border: 1px solid rgba(25, 118, 210, 0.2);
  border-radius: 50%;
  color: #1976d2;
  background-color: rgba(25, 118, 210, 0.1);
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const PopupHeading = styled.h2`
  color: #0c3688
`;

const Map = (props) => {
  const leafletAttribution = document.querySelector(".leaflet-control-attribution.leaflet-control a[href='https://leafletjs.com']");
  if (leafletAttribution) {
    leafletAttribution.removeAttribute("href");
  }
  const params = useResolvedPath();
  const [activeitem, setActiveitem] = useState(null);
  const [lat, setLat] = useState(props.lat);
  const [lng, setLng] = useState(props.lng);
  const [zoom, setZoom] = useState(props.zoom);
  const [map1, setMap1] = useState();
  const [marker1, setMarker1] = useState();
  let dealerName;
  let dealerAddress;
  let dealerPhoneno;
  let dealerMail;
  let dealerItem;
  let dealerList = props.dealerList;
  let userLatng = props.userLatng;
  let dealercardLatlong = props.dealercardLatlong;
  let setdealercardLatlong = props.setdealercardLatlong;
  let activeDiv = props.activeDiv;
  let isMobile = props.isMobile;
  let categoryless_required = props.categoryless_required;
  let mapDivStyle = { display: 'block' };
  const popupRef = useRef(null);

  if (isMobile) {
    mapDivStyle = {
      display: !activeDiv ? 'block' : 'none'
    }
  }

  useEffect(() => {
    setLat(props.lat);
    setLng(props.lng);
    setZoom(props.zoom);

    const dir_icon = `<img src=${require('./../../Assets/directions.svg').default} height='20px' width='20px'></img>`;
    const phone_icon = `<img src=${require('./../../Assets/phone.svg').default} height='20px' width='20px'></img>`;
    const email_icon = `<img src=${require('./../../Assets/email.svg').default} height='20px' width='20px'></img>`;
    if (dealercardLatlong && dealercardLatlong !== null) {
      const item = dealerList.find((dealer) => dealer.coordinates.latitude === dealercardLatlong.latitude);
      if (item) {
        dealerItem = item;
        dealerName = item.name;
        dealerAddress = item.address;
        dealerMail = item.email_id;
        dealerPhoneno = item.phone_no;
        const phoneNumbers = [];
        if (dealerPhoneno) {
          if (dealerPhoneno.includes('/')) {
            const arr = dealerPhoneno.split('/');
            arr.map((element) => {
              phoneNumbers.push(element.trim());
            });
          } else {
            phoneNumbers.push(item.phone_no);
          }
        }
        var phone_no_split = phoneNumbers.map((item) => {
          return (
            `<div class="sc-ivnCJf popupLineItem"><div class="sc-cyRfQX popupItem"><a href="tel:${item}" style="display:flex">${phone_icon}</a></div>${item}</div>`
          );
        })
        var phonecontent = phone_no_split.join('');
      }


      map1.flyTo([dealercardLatlong.latitude, dealercardLatlong.longitude], 16);
      if (props.show_mail_in_map_popup == "true" && dealerMail) {
        var popup = L.popup()
          .setLatLng([dealercardLatlong.latitude, dealercardLatlong.longitude])

          .setContent(`<div><h2 class="sc-lnAgIa popupHeading">${dealerName}</h2><hr class="MuiDivider-root MuiDivider-fullWidth popupHr"><div class="sc-ivnCJf popupLineItem"><div class="sc-cyRfQX popupItem" onclick="(function(){ const url = 'https://www.google.com/maps/dir/${userLatng}/${dealerItem.coordinates.latitude},${dealerItem.coordinates.longitude}'; window.open(url, '_blank'); })()">${dir_icon}</div>${dealerAddress}</div>${phonecontent}<div class="sc-ivnCJf popupLineItem" style="color:#0c3688;text-decoration:underline;cursor:pointer"><div class="sc-cyRfQX popupItem"><a href="mailto:${dealerMail}" style="display:flex">${email_icon}</a></div>${dealerMail}</div></div>`)
          .openOn(map1);
      }
      else {
        var popup = L.popup()
          .setLatLng([dealercardLatlong.latitude, dealercardLatlong.longitude])

          .setContent(`<div><h2 class="sc-lnAgIa popupHeading">${dealerName}</h2><hr class="MuiDivider-root MuiDivider-fullWidth popupHr"><div class="sc-ivnCJf popupLineItem"><div class="sc-cyRfQX popupItem" onclick="(function(){ const url = 'https://www.google.com/maps/dir/${userLatng}/${dealerItem.coordinates.latitude},${dealerItem.coordinates.longitude}'; window.open(url, '_blank'); })()">${dir_icon}</div>${dealerAddress}</div>${phonecontent}<div class="sc-ivnCJf popupLineItem" style="color:#0c3688;text-decoration:underline;cursor:pointer">`)
          .openOn(map1);
      }
    }
  }, [props.lat, props.lng, zoom, dealercardLatlong]);

  const handlePopupOpen = () => {
    // if (popupRef.current) {
    //   popupRef.current.leafletElement.update();
    // }
  };


  const MyMarker = ({ position, item, zoom }) => {
    const map = useMap();

    useEffect(() => {
      setMap1(map);
    }, [map]);

    const handleClick = (event) => {
      // map.flyTo(position);
      setActiveitem(item);
    }

    function flyto(position, zoom, item) {
      map.flyTo(position, zoom);
    }

    const handleGetDirectionsPopup = (item) => {
      const LaT = item.coordinates.latitude;
      const LnG = item.coordinates.longitude;
      if (LaT && LnG) {
        const url = `https://www.google.com/maps/dir/${userLatng}/${LaT},${LnG}`;
        window.open(url, '_blank');
      }
    }

    const splitPhoneNumber = () => {
      const phoneNumbers = [];
      if (item.phone_no.includes('/')) {
        const arr = item.phone_no.split('/');
        arr.map((element) => {
          phoneNumbers.push(element.trim());
        });
      } else {
        phoneNumbers.push(item.phone_no);
      }
      return phoneNumbers.map((item) => {
        return (
          <PopupItems key={uuidv4()}>
            <PopupIcons>
              <a href={`tel:${item}`} style={{ display: 'flex' }}>
                <CallOutlinedIcon fontSize="small" />
              </a>
            </PopupIcons>
            {item}
          </PopupItems>
        );
      })
    }

    return (
      <Marker
        key={uuidv4()}
        position={[
          item.coordinates.latitude,
          item.coordinates.longitude
        ]}
        icon={bullsEyeIcon}
        eventHandlers={{
          click: () => {
            flyto(position, zoom, item)
          },
        }}>
        {popup(item)}
      </Marker>
    );

    function popup(item) {
      return (
        <Popup ref={popupRef} onOpen={handlePopupOpen}>
          <div>
            <PopupHeading>{item.name && item.name}</PopupHeading>
            <Divider />
            <PopupItems>
              <PopupIcons onClick={() => handleGetDirectionsPopup(item)}>
                <DirectionsIcon fontSize="small" />
              </PopupIcons>
              {item.address}
            </PopupItems>
            {item.phone_no && splitPhoneNumber(item.phone_no)}
            {props.show_mail_in_map_popup == "true" &&
              item.email_id && item.email_id.trim() !== '' ?
              <PopupItems style={{ color: '#0C3688', textDecoration: 'underline', cursor: 'pointer' }}>
                <PopupIcons>
                  <a href={`mailto:${item.email_id}`} style={{ display: 'flex' }}>
                    <EmailOutlinedIcon fontSize="small" />
                  </a>
                </PopupIcons>
                {item.email_id}
              </PopupItems>
              : <></>
            }
          </div>
        </Popup>
      );
    }
  };

  const createClusterCustomIcon = function (cluster) {
    const childCount = cluster.getChildCount();
    return L.divIcon({
      html: `<span>${childCount}</span>`,
      className: 'custom-marker-cluster',
      iconSize: L.point(33, 33, true),
    });
  }

  return (
    <div className="map_container" style={mapDivStyle}>
      {(lat != null && lng != null) &&
        <MapContainer style={{ height: categoryless_required === 'true' ? '87vh' : '92vh', }} center={[lat, lng]} scrollWheelZoom={false} zoomControl={false} maxZoom={24} zoom={zoom}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution=' Powered Tiles by &copy; Maptiler</a>, Map & Data by &copy; <a href="https://www.latlong.ai/" target="_blank">Latlong</a>'
          />
          {
            params.pathname.split('/')[1] == 'tvs_colombia 143' ?
              <MarkerClusterGroup chunkedLoading onMouseOver={() => { }} showCoverageOnHover={false} iconCreateFunction={createClusterCustomIcon}>
                {dealerList && dealerList.map(item => (
                  item.coordinates.latitude !== null ?
                    <MyMarker
                      key={uuidv4()}
                      position={[
                        item.coordinates.latitude,
                        item.coordinates.longitude
                      ]}
                      item={item}
                      zoom={16}
                    >
                    </MyMarker>
                    :
                    <></>
                ))}
              </MarkerClusterGroup>
              :
              <>
                {dealerList && dealerList.map(item => (
                  item.coordinates.latitude !== null ?
                    <MyMarker
                      key={uuidv4()}
                      position={[
                        item.coordinates.latitude,
                        item.coordinates.longitude
                      ]}
                      item={item}
                      zoom={16}
                    >
                    </MyMarker>
                    :
                    <></>
                ))}
              </>
          }
          <ZoomControl position="bottomright" />
        </MapContainer>
      }
    </div>
  )

}

export default Map;
