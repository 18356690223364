import React from "react";
import styled from "styled-components";
import DealerButtons from "../Functional/DealerButton";

const DealerTypeBar = styled.div`
  position: relative;
  height: 5vh;
  width: auto;
  background: #EEF4FF;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start; 
`;

const DealerTypeLabel = styled.div`
 font-size: 14px;
 font-weight: bold;
 padding: 16px;
`;

const DealerType = (props) => {

  let setTwoWheeler = props.setTwoWheeler;
  let setThreeWheeler = props.setThreeWheeler;
  let threeWheeler = props.threeWheeler;
  let twoWheeler = props.twoWheeler;
  let language = props.language;
  let dealerTypePresent = props.dealerTypePresent;
  let twoWheelerCategoryPresent = props.twoWheelerCategoryPresent;
  let threeWheelerCategoryPresent = props.threeWheelerCategoryPresent;

  if(dealerTypePresent.toLowerCase() == 'true'){
    return (
      <DealerTypeBar>
        <DealerTypeLabel className="dealer_type_Text_latlong">{language.DealerType}:</DealerTypeLabel>
        <DealerButtons setTwoWheeler={setTwoWheeler} setThreeWheeler={setThreeWheeler} threeWheeler={threeWheeler} twoWheeler={twoWheeler} language={language} twoWheelerCategoryPresent={twoWheelerCategoryPresent} threeWheelerCategoryPresent={threeWheelerCategoryPresent}/>
      </DealerTypeBar>
    )
  }
}

export default DealerType;