import ReactGA4 from "react-ga4";

const initializeGoogleAnalytics = ( google_analytics_id, universal_analytics_id ) => {

  // Initialize GA4 - Add your measurement ID
  ReactGA4.initialize(google_analytics_id);

  // console.log("GA INITIALIZED", google_analytics_id, universal_analytics_id);
};

// Function to track Google Analytics event
const trackGoogleAnalyticsEvent = (category, action, label, searchTerm) => {
  const eventParameters = {
    category: category,
    action: action,
    label: label,
  };

  if (searchTerm) {
    eventParameters.search_term = searchTerm;
  }

  ReactGA4.event(eventParameters);
};

// Export the functions to be used in other components
export { initializeGoogleAnalytics, trackGoogleAnalyticsEvent };
