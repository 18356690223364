import React, { useState, useEffect } from 'react';
import Header from '../Components/SubComponents/NonFunctional/Header';
import DealerTypeBar from '../Components/SubComponents/NonFunctional/DealerTypeBar';
import DealerSidebar from '../Components/MainComponents/DealerSidebar';
import Map from '../Components/MainComponents/Map';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import LanguageConfig from './../Data/LanguageConfig.json';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import Button from '@mui/material/Button';
import { Block } from '@mui/icons-material';
import { display } from '@mui/system';
import { initializeGoogleAnalytics } from './../Components/MainComponents/GoogleAnalytics';

const AllCountries = (props) => {
  const [accessToken, setAccessToken] = useState('');
  const [dealerList, setDealerList] = useState('');
  const [configData, setConfigData] = useState(props.config);
  const [twoWheeler, setTwoWheeler] = useState(true);
  const [threeWheeler, setThreeWheeler] = useState(false);
  const [activeDiv, setActiveDiv] = useState(true);
  const [dealerCardSelected, setDealerCardSelected] = useState();
  const [searchedPlace, setSearchedPlace] = useState('');
  const [userLatlng, setUserLatlng] = useState([]);
  const [dealercardLatlong, setdealercardLatlong] = useState(null);
  const two_letter_country_code = props.config.two_letter_country_code;
  const params = useParams();
  const [language, setLanguage] = useState('');
  const [isMobile, setIsMobile] = useState(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  var twoWheelerCategory_bool;
  var threeWheelerCategory_bool;
  initializeGoogleAnalytics(props.config.google_analytics_id, props.config.universal_analytics_id)
  // console.log(props.config.google_analytics_id, props.config.universal_analytics_id, 'chandan');

  let country_view_on_load = props.config.country_view_on_load
  if (props.config.category_id != undefined || props.config.category_id != null) {
    twoWheelerCategory_bool = 'true'
  }
  else {
    twoWheelerCategory_bool = 'false'
  }

  if (props.config.categoryIdThreeW != undefined || props.config.categoryIdThreeW != null) {
    threeWheelerCategory_bool = 'true'
  }
  else {
    threeWheelerCategory_bool = 'false'
  }

  useEffect(() => {
    const fetchAccessToken = async () => {
      try {
        const response = await axios.post(`https://ib_api.latlong.in/oauth/token`, {
          grant_type: 'client_credentials',
          client_id: props.config.client_id,
          client_secret: props.config.client_secret
        });
        const { access_token } = response.data;
        setAccessToken(access_token);
        // getDealerList(access_token);
      } catch (error) {
        // console.log(error);
      }
    };
    setLanguage(LanguageConfig[params.lang]);
    
    fetchAccessToken();
  }, []);

  const getDealerList = async (token) => {
    try {
      const response = await axios.get(`https://ib_api.latlong.in/brands/${props.config.brand_id}/all_stores`, {
        params: {
          country: props.config.country_code,
          lang: params.lang !== 'en' ? props.config.wh_lang : '',
          category_id: threeWheeler ? props.config.categoryIdThreeW : props.config.category_id,
          access_token: token
        }
      });
      setLanguage(LanguageConfig[params.lang]);
      setDealerList(response.data);
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    const success = (position) => {
      if (country_view_on_load=='true' && accessToken){
        getDealerList(accessToken)
      } else {
      const { latitude, longitude } = position.coords;
      setUserLatlng([latitude, longitude]);
      // setdealercardLatlong([latitude, longitude]);      

      if (accessToken) {
        axios({
          method: 'GET',
          url: `https://ib_api.latlong.in/brands/${props.config.brand_id}/search?lat=${latitude}&long=${longitude}&country=${props.config.country_code}&lang=${params.lang !== 'en' ? props.config.wh_lang : ''}&category_id=${threeWheeler ? props.config.categoryIdThreeW : props.config.category_id}&access_token=${accessToken}`
        })
          .then((response) => {
            setSearchedPlace(response.data.lr.name);
            setDealerList(response.data.stores);
          })
          .catch((error) => {
            // console.log(error);
            getDealerList(accessToken)
          });
      }
      }
    };

    const fetchDefaultDealerList = async () => {

      if (accessToken) {
        try {
          const response = await axios.get(`https://ib_api.latlong.in/brands/${props.config.brand_id}/all_stores`, {
            params: {
              country: props.config.country_code,
              lang: params.lang !== 'en' ? props.config.wh_lang : '',
              category_id: threeWheeler ? props.config.categoryIdThreeW : props.config.category_id,
              access_token: accessToken
            }
          });
          setDealerList(response.data);
        } catch (error) {
          // console.log(error);
        }
      }
    };

    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(success, fetchDefaultDealerList);
      } else {
        fetchDefaultDealerList();
      }
    };

    getLocation();
  }, [twoWheeler, threeWheeler, accessToken]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Add event listener on component mount
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    screenWidth <= 600 ? setIsMobile(true) : setIsMobile(false);
  }, [screenWidth])

  const handleMapToggleNew = () => {
    setActiveDiv(prevState => !prevState);
    const leafletContainer = document.getElementsByClassName('leaflet-container')[0];
    if (leafletContainer) {
      if (isMobile) {
        leafletContainer.style.width = '100vw';
      } else {
        leafletContainer.style.width = '70vw';
      }
    }
  };

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    position: "fixed",
    zIndex: '1000',
    height: '50px',
    width: "100vw",
    marginTop: '8px'
  };


  return (
    <div style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
      <Header isMobile={isMobile} accessToken={accessToken} setDealerList={setDealerList} configData={configData} setSearchedPlace={setSearchedPlace} setUserLatlng={setUserLatlng} language={language} />
      <DealerTypeBar setTwoWheeler={setTwoWheeler} twoWheeler={twoWheeler} setThreeWheeler={setThreeWheeler} threeWheeler={threeWheeler} language={language} dealerTypePresent={props.config.categoryless_required} twoWheelerCategoryPresent={twoWheelerCategory_bool} threeWheelerCategoryPresent={threeWheelerCategory_bool} />
      <div style={{
        width: '100vw',
        height: configData.categoryless_required === 'true' ? '87vh' : '92vh',
        display: 'flex',
        flexDirection: 'row'
      }}>
        {
          isMobile &&
          <div style={containerStyle}>
            <Button
              style={{ height: '50px', display: 'flex', flexDirection: 'column' }}
              variant={activeDiv ? "outlined" : "contained"}
              onClick={handleMapToggleNew}
            >
              {activeDiv ? <MapOutlinedIcon /> : <ListOutlinedIcon />}
              {activeDiv ? language.Map : language.List}
            </Button>
          </div>
        }
        <DealerSidebar isMobile={isMobile} dealerList={dealerList} country={props.config.country} two_letter_country_code={two_letter_country_code} setActiveDiv={setActiveDiv} activeDiv={activeDiv} setDealerCardSelected={setDealerCardSelected} searchedPlace={searchedPlace} setUserLatlng={setUserLatlng} userLatlng={userLatlng} setdealercardLatlong={setdealercardLatlong} accessToken={accessToken} configData={configData} language={language} sendsms_required={props.config.sendsms_required} categoryless_required={configData.categoryless_required}>
        </DealerSidebar>
        <Map isMobile={isMobile} activeDiv={activeDiv} dealerList={dealerList} lat={props.config.lat} lng={props.config.lng} zoom={props.config.zoom} userLatlng={userLatlng} dealercardLatlong={dealercardLatlong} setdealercardLatlong={setdealercardLatlong} categoryless_required={configData.categoryless_required} show_mail_in_map_popup={configData.show_mail_in_map_popup}/>
      </div>
    </div >
  );
};

export default AllCountries;