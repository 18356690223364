import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import { Button } from "@mui/material";
import DealerInformation from "./DealerInformation";
import NearMeIcon from '@mui/icons-material/NearMe';
import DealerContactForm from "./DealerContactForm";
import CloseIcon from '@mui/icons-material/Close';
import { createFilterOptions } from "@mui/material";
// import { trackGoogleAnalyticsEvent } from "../../MainComponents/GoogleAnalytics";

// initializeGoogleAnalytics();
const filter = createFilterOptions();

const handleDealerSelect = (center_name) => {
};

var options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};

function errors(err) {
  console.warn(`ERROR(${err.code}): ${err.message}`);
}

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <Button {...other} />;
})(({ theme, expand }) => ({
  // transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  // transition: theme.transitions.create('transform', {
  //   duration: theme.transitions.duration.shortest,
  // }),
}));

const DealerSection = styled(Card)`
&&{
  cursor: pointer;
  margin-top: 0;
  border-bottom: 1px solid silver;
  &: hover{
    background-color: #F4F4F4;
  }
}
`;


const DealerCard = (props) => {

  const [expanded, setExpanded] = useState(false);
  const [selectedCard, setselectedCard] = useState(null);
  const [prevselectedCard, setprevselectedCard] = useState('');
  const [isSelected, setIsSelected] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false); // Track button click

  let setUserLatlng = props.setUserLatlng;
  let userLatlng = props.userLatlng;
  let dealerList = props.dealerList;
  let setDealerCardSelected = props.setDealerCardSelected;
  let two_letter_country_code = props.two_letter_country_code;
  let setdealercardLatlong = props.setdealercardLatlong;
  let language = props.language;
  let sendsms_required = props.sendsms_required;
  let expandComponent = null;
  let configData = props.configData;
  // setDealerCardSelected(props.dealerList.coordinates);

  const handleExpandClick = () => {
    setExpanded(!expanded);
    handleDealerSelect(dealerList.id);
  };

  // const GA_MEASUREMENT_ID = 'G-K37JN0V35J';
  function ga4(GA_MEASUREMENT_ID, center_name, element_Id) {
    console.log('rev', GA_MEASUREMENT_ID, center_name, element_Id )
    // Create the script element
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`;

    // Append the script to the head of the document
    document.head.appendChild(script);

    // Set up the 'onload' callback for the script
    script.onload = () => {
      // Define the gtag initialization function
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }

      // Initialize Google Analytics
      gtag('js', new Date());
      gtag('config', GA_MEASUREMENT_ID);

      // Now, you can use the gtag function
        gtag('event', 'dealer_select', {
          'cta_text': 'Get Direction',     // Replace with the text of the button clicked
          'center_name': center_name
        });
    };

    // Clean up by removing the script when the component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }

  const handleGetDirections = (element_Id) => {
    // Send GA4 analytics only if the button hasn't been clicked before
    if (!buttonClicked) {
      ga4(configData.google_analytics_id, dealerList.name, element_Id);
      setButtonClicked(true); // Mark the button as clicked
    }
    handleDealerSelect(dealerList.id);
    // console.log(dealerList.dealer_id, 'dealerList.dealer_id')
    const url = `https://www.google.com/maps/dir/${userLatlng}/${dealerList.coordinates.latitude},${dealerList.coordinates.longitude}`;
    window.open(url, '_blank');
  }

  const dealerSectionHandler = (event) => {
    setprevselectedCard('asvd')
    setselectedCard(props.dealerList.id)
    const selectedElements = document.getElementsByClassName('selected');
    for (let i = 0; i < selectedElements.length; i++) {
      selectedElements[i].classList.remove('selected');
    }
    var d = document.getElementById(props.dealerList.id);
    d.className += " selected";
    setdealercardLatlong(props.dealerList.coordinates);
    setDealerCardSelected(props.dealerList.coordinates);
  };


  useEffect(() => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
          if (result.state === "granted") {
            //If granted then you can directly call your function here
            navigator.geolocation.getCurrentPosition(success);
          } else if (result.state === "prompt") {
            navigator.geolocation.getCurrentPosition(success, errors, options);
          } else if (result.state === "denied") {
            //If denied then you have to show instructions to enable location
          }
          result.onchange = function () {
          };
        });
    } else {
      alert("Sorry Not available!");
    }
  }, [])

  function success(pos) {
    let geo_latitude = pos.coords.latitude;
    let geo_longitude = pos.coords.longitude;
    // setUserLatlng([geo_latitude, geo_longitude])
  }

  if (props.sendsms_required.toLowerCase() == 'true') {
    expandComponent = (
      <ExpandMore
        variant={!expanded ? 'outlined' : 'contained'}
        style={{
          height: '30px',
          width: '180px',
          fontSize: '12px',
          border: '15px solid primary',
        }}
        size={'small'}
        expand={expanded}
        onClick={handleExpandClick}
        aria-expanded={expanded}
        aria-label="show more"
      >
        <ChatOutlinedIcon style={{ marginRight: '4px' }} fontSize="small" />
        {language.ContactMe}
      </ExpandMore>
    );
  }

  return (
    <DealerSection onClick={dealerSectionHandler}>
      <CardContent id={props.dealerList.id} style={{ padding: '12px' }}>
        <DealerInformation dealerList={dealerList} language={language} configData={configData} />
        <CardActions style={{ display: 'flex', justifyContent: 'space-evenly', padding: "0" }}>
          <Button
            id={`get_directions_${dealerList.id}`}
            variant='outlined'
            size={'small'}
            style={{
              height: '30px',
              fontSize: '12px',
              border: '1.5px solid primary',
              maxWidth: '100%', // Limit the width to its container
              whiteSpace: 'nowrap', // Prevent text from wrapping
              overflow: 'hidden', // Hide overflowing content
              textOverflow: 'ellipsis',
            }}
            onClick={() => handleGetDirections(`get_directions_${dealerList.id}`)}>
            <NearMeIcon style={{ marginRight: '4px' }} fontSize="small" />
            {language.GetDirections}
          </Button>
          {expandComponent}
        </CardActions>
      </CardContent>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent sx={{ paddingBottom: '16px !important' }}>
          <CloseIcon style={{ float: 'right', color: '#1976d2' }} onClick={handleExpandClick} />
          <DealerContactForm dealerList={dealerList} two_letter_country_code={two_letter_country_code} language={language} />
        </CardContent>
      </Collapse>
    </DealerSection>
  );
}

export default DealerCard;