import React, { useState, useEffect } from "react";
import styled from "styled-components";
import DealerStack from "../SubComponents/Functional/DealerStack";
import Button from '@mui/material/Button';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';

const Sidebar = styled.div`
  width: 100vw;    
`;

const TotalDealersFound = styled.div`

  height: 64px;
  width: 100%;
  border-bottom: 1px solid lightgrey;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin: auto;

  & p {
    font-size: 14px;
    text-align: start;
    margin-left: 1em;
  }

  & Button {
    display: flex;
    flex-direction: column;
    font-size: 10px;
    padding: 2px;
    margin: 4px;
  }
`;

const Btn = styled(Button)`
  && {
    widhth: 100px;

  }
`;

const dealerTypeBar = styled.div`
  height:90%;
  overflow:auto;
`;

const DealerSidebar = (props) => {
  const [toggleDiv, setToggleDiv] = useState();

  let setActiveDiv = props.setActiveDiv;
  let activeDiv = props.activeDiv;
  let dealerList = props.dealerList;
  let country = props.country;
  let setDealerCardSelected = props.setDealerCardSelected;
  let searchedPlace = props.searchedPlace;
  let setUserLatlng = props.setUserLatlng;
  let userLatlng = props.userLatlng;
  let two_letter_country_code = props.two_letter_country_code;
  let setdealercardLatlong = props.setdealercardLatlong;
  let accessToken = props.accessToken;
  let language = props.language;
  let categoryless_required = props.categoryless_required;
  let sidebarStyle = { display: 'block', height: categoryless_required === 'true' ? '87vh' : '92vh' };
  let isMobile = props.isMobile;
  let sendsms_required = props.sendsms_required;
  let configData = props.configData

  if (isMobile) {
    sidebarStyle = {
      display: activeDiv ? 'block' : 'none'
    }
  }

  return (
    <Sidebar style={sidebarStyle}>
      <TotalDealersFound >
        {dealerList.length > 0 &&
          <p style={
            {marginRight: isMobile? '60px':'0px'}
          }> {language.Showing} <br /><b>{dealerList.length} TVS {language.Dealers}</b>
            &nbsp;
            {
              searchedPlace ? `${language.Near}` : `${language.In}`
            }
            &nbsp;
            {
              searchedPlace ? searchedPlace : country
            }
          </p>
        }
        {/* <Button variant={activeDiv === "list" ? "contained" : "outlined"} onClick={() => handleMapToggle("list")}>
          <ListOutlinedIcon />
          {language.List}
        </Button>
        <Button variant={activeDiv === "map" ? "contained" : "outlined"} onClick={() => handleMapToggle("map")}>
          <MapOutlinedIcon />
          {language.Map}
        </Button> */}
      </TotalDealersFound>
      {/* {
        activeDiv === "list" ? */}
      <DealerStack setDealerCardSelected={setDealerCardSelected} configData={configData} two_letter_country_code={two_letter_country_code} dealerList={dealerList} setUserLatlng={setUserLatlng} userLatlng={userLatlng} setdealercardLatlong={setdealercardLatlong} accessToken={accessToken} language={language} sendsms_required={sendsms_required} categoryless_required={categoryless_required}
      >
      </DealerStack>
      {/* : <></>
      } */}
    </Sidebar>
  )
}

export default DealerSidebar;