import React from "react";
import styled from "styled-components";
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoneIcon from '@mui/icons-material/Done';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { v4 as uuidv4 } from 'uuid';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const handleDealerSelect = (searchTerm, GA_MEASUREMENT_ID, element_Id, ctaText, mail_id) => {
  if(ctaText=='Get Mail'){
    // window.location.href = `mailto:${searchTerm}`;
    window.open(`mailto:${mail_id}`, '_blank');
  }
  // console.log(searchTerm)
  // trackGoogleAnalyticsEvent('Detect', 'dealer_search', 'Dealers Near You');
  // Create the script element
  const script = document.createElement('script');
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`;

  // Append the script to the head of the document
  document.head.appendChild(script);

  // Set up the 'onload' callback for the script
  script.onload = () => {
    // Define the gtag initialization function
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }

    // Initialize Google Analytics
    gtag('js', new Date());
    gtag('config', GA_MEASUREMENT_ID);

    // Now, you can use the gtag function
    gtag('event', 'dealer_select', {
      'cta_text': ctaText,     // Replace with the text of the button clicked
      'center_name': searchTerm
    });
  };

  // Clean up by removing the script when the component unmounts
  return () => {
    document.head.removeChild(script);
    };
};

const DealerContentDiv = styled.div`
  padding : 8px;
  width : 90%;
  font-weight: bold;
  font-size: 14px;
`;

const LineItemDiv = styled.div`
  display: flex;
  // margin: 4px;
`;

const IconDiv = styled.div`
  padding : 4px;
  width : 8%;
  margin-right: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const IconLink = styled.a`
  padding : 4px;
  width : 8%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SearchDistance = styled.div`
  font-size: 12px;
  padding: 4px;
  color: #1976d2;
  font-weight: bold;
  text-align: center;
`;


const DealerInformation = (props) => {

  const [showIcon, setShowIcon] = React.useState(false);
  const [openCopy, setOpenCopy] = React.useState(false);
  const [isCopied, setIsCopied] = React.useState(false);
  let configData = props.configData;
  let dealerList = props.dealerList;
  let language = props.language;

  const splitPhoneNumber = () => {
    const phoneNumbers = [];
    if (dealerList.phone_no.includes('/')) {
      const arr = dealerList.phone_no.split('/');
      arr.map((element) => {
        phoneNumbers.push(element.trim());
      });
    } else {
      phoneNumbers.push(dealerList.phone_no);
    }
    return phoneNumbers.map((item, index) => {
      return (
        <LineItemDiv key={index}>
          <IconLink href={`tel:${item}`} id={`ga4_phone_${dealerList.id}`} onClick={() => handleDealerSelect(dealerList.name, configData.google_analytics_id, `ga4_phone_${dealerList.id}`, 'Get Phone', item)}>
            <IconDiv>
              <CallOutlinedIcon color="primary" fontSize="small" />
            </IconDiv>
          </IconLink>
          <DealerContentDiv>
            {item}
          </DealerContentDiv>
        </LineItemDiv >
      );
    })
  }



  const handleMouseEnter = () => {
    setShowIcon(true);
  };

  const handleMouseLeave = () => {
    setShowIcon(false);
  };

  const handleCopyAddress = (address) => {
    navigator.clipboard.writeText(address);
    setOpenCopy(true);
    setIsCopied(true);
  }

  const handleCopyClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsCopied(false);
    setOpenCopy(false);
  };

  return (
    <>
      <LineItemDiv >
        <IconDiv>
          <LocationOnOutlinedIcon color="primary" fontSize="small" />
        </IconDiv>
        <DealerContentDiv
          style={{ fontSize: '14px' }}
        >
          {dealerList.name}
        </DealerContentDiv>
      </LineItemDiv>
      <LineItemDiv onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {dealerList.address && dealerList.address !== '' ?
          <>
            <IconDiv>
              <SearchDistance>
                {
                  dealerList && dealerList.distance
                }
              </SearchDistance>
            </IconDiv>
            <DealerContentDiv style={{ fontWeight: 'normal' }}>
              {dealerList.address && dealerList.address}
            </DealerContentDiv>
          </>
          :
          <></>
        }
        {
          showIcon &&
          (isCopied ?
            (
              <DoneIcon color="success" fontSize="small" />
            ) :
            (dealerList.address &&
              <ContentCopyIcon color="primary" fontSize="small" onClick={() => handleCopyAddress(dealerList.address)} />
            )
          )
        }
        <Snackbar open={openCopy} autoHideDuration={6000} onClose={handleCopyClose}>
          <Alert onClose={handleCopyClose} severity="info" sx={{ width: '100%' }}>
            {language.CopySuccess}
          </Alert>
        </Snackbar>
      </LineItemDiv>
      {dealerList.phone_no && splitPhoneNumber()}
      {configData.show_mail_in_dealer_card =="true" && 
        dealerList.email_id && dealerList.email_id.trim() !== '' ?
          <LineItemDiv >
            <IconLink id={`ga4_email_${dealerList.id}`} onClick={() => handleDealerSelect(dealerList.name, configData.google_analytics_id, `ga4_email_${dealerList.id}`, 'Get Mail', dealerList.email_id)}>
              <IconDiv>
                <EmailOutlinedIcon color="primary" fontSize="small" />
              </IconDiv>
            </IconLink>
            <DealerContentDiv>
              {dealerList.email_id}
            </DealerContentDiv>
          </LineItemDiv>
          : <></>
      }

    </>

  );
}

export default DealerInformation;