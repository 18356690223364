import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { Button } from "@mui/material";

const DashboardConatiner = styled.div`
  height: 280vh;
  width: 100vw;
  background-color: teal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Btn = styled(Button)`
  &&{
    margin: 16px;
    width: 180px;
  }
`;

const Nav = styled(NavLink)`
  &&{
    text-decoration: none;
    color: white;
    width: inherit;
    height: inherit;
  }
`;

const Dashboard = () => {

  return (
    <DashboardConatiner>
      <Btn variant="contained" >
        <Nav to="/tvs_mexico/en">Mexico</Nav>
      </Btn>
      <Btn variant="contained"  >
        <Nav to="/tvs_guatemala/en">Guatemala</Nav>
      </Btn>
      <Btn variant="contained" >
        <Nav to="/tvs_nepal/en">Nepal</Nav>
      </Btn>
      <Btn variant="contained"  >
        <Nav to="/tvs_panama/en">Panama</Nav>
      </Btn>
      <Btn variant="contained"  >
        <Nav to="/tvs_philippines/en">Philippines</Nav>
      </Btn>
      <Btn variant="contained"  >
        <Nav to="/tvs_singapore/en">Singapore</Nav>
      </Btn>
      <Btn variant="contained"  >
        <Nav to="/tvs_honduras/en">Honduras</Nav>
      </Btn>
      <Btn variant="contained"  >
        <Nav to="/tvs_colombia/en">Colombia</Nav>
      </Btn>
      <Btn variant="contained"  >
        <Nav to="/tvs_nigeria/en">Nigeria</Nav>
      </Btn>
      <Btn variant="contained"  >
        <Nav to="/tvs_venezuela/en">Venezuela</Nav>
      </Btn>
      <Btn variant="contained"  >
        <Nav to="/tvs_indonesia/en">Indonesia</Nav>
      </Btn>
      <Btn variant="contained"  >
        <Nav to="/tvs_peru/en">Peru</Nav>
      </Btn>
      <Btn variant="contained"  >
        <Nav to="/tvs_peru/2w/en">Peru Two Wheeler</Nav>
      </Btn>
      <Btn variant="contained"  >
        <Nav to="/tvs_peru/3w/en">Peru Three Wheeler</Nav>
      </Btn>
      <Btn variant="contained"  >
        <Nav to="/tvs_el_salvador/en">El Salvador</Nav>
      </Btn>
      <Btn variant="contained"  >
        <Nav to="/tvs_chile/en">Chile</Nav>
      </Btn>
      <Btn variant="contained"  >
        <Nav to="/tvs_nicaragua/en">Nicaragua</Nav>
      </Btn>
      <Btn variant="contained"  >
        <Nav to="/tvs_costarica/en">Costa Rica</Nav>
      </Btn>
      <Btn variant="contained"  >
        <Nav to="/tvs_ecuador/en">Ecuador</Nav>
      </Btn>
      <Btn variant="contained"  >
        <Nav to="/tvs_bolivia/en">Bolivia</Nav>
      </Btn>
      <Btn variant="contained"  >
        <Nav to="/tvs_dominican_republic/en">DOminica Republic</Nav>
      </Btn>
      <Btn variant="contained"  >
        <Nav to="/tvs_haiti/en">Haiti</Nav>
      </Btn>
      <Btn variant="contained"  >
        <Nav to="/tvs_paraguay/en">Paraguay</Nav>
      </Btn>
      <Btn variant="contained"  >
        <Nav to="/tvs_uruguay/en">Uruguay</Nav>
      </Btn>
      <Btn variant="contained"  >
        <Nav to="/tvs_italy/en">Italy</Nav>
      </Btn>
      <Btn variant="contained"  >
        <Nav to="/tvs_argentina/en">Argentina</Nav>
      </Btn>
    </DashboardConatiner>
  );
}

export default Dashboard;