import React, { useEffect, useState } from "react";
import styled from "styled-components";
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Button } from "@mui/material";
import axios from "axios";
import { useForm } from 'react-hook-form';
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import 'react-phone-number-input/style.css';
import flags from 'react-phone-number-input';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const StyledInputLabel = styled(InputLabel)`
  && {
    font-size: 14px;
  }
`;

const ContactFormInfo = styled.div`
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  margin-top: 4px;
`;

const LineItemDiv = styled.div`
  display: flex;
  margin: 4px;
`;

const FormLabelDiv = styled.div`
  padding : 4px;
  width : 30%;
  margin-right: 4px;
  padding-right: 4px;
  display: flex;
  justify-content: start;
  align-items: center;
`;

const FormInputDiv = styled.div`
  // padding : 8px;
  width : 100%;
  font-weight: bold;
`;


const StyledPhoneInput = styled(PhoneInput)`
  // border: 1px solid ${props => props.error ? 'red' : '#ccc'};
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
  color: ${props => props.disabled ? '#ccc' : '#333'};
  width: 100%;
  box-sizing: border-box;

  & input {
    color: #333;
    border: none;
    outline: none;
    border-bottom: 1px solid black;
    padding: 0;
    font-size: 14px;
    width: 100%;
    box-sizing: border-box;
    transition: all 0.3s ease;
    background-color: transparent;

    &:hover{
      border: none;
      outline: none;
      border-bottom: 1px solid black;
    }
    &:focus {
      border: none;
      outline: none;
      border-bottom: 1px solid #3f51b5;
    }
  }
`;

const DealerContactForm = (props) => {

  const [submitStatus, setSubmitStatus] = useState(null);
  const { register, handleSubmit } = useForm();
  const handleEnquiry = (enquirydata) => {
    let data = JSON.stringify({
      "DealerEnquiryId": 0,
      "DealerExternalUniqueId": dealerList.dealer_id,
      "DealerName": dealerList.name,
      "DealerAddress": dealerList.address,
      "DealerEmail": dealerList.email_id,
      "DealerCountryCode": "country_id_from_tvs",
      "DealerMobileNumber": dealerList.phone_no,
      "DealerWhatsAppNumber": "string",
      "DistributorName": "string",
      "UserFullName": enquirydata.name,
      "UserCountryCode": enquirydata.country_code,
      "UserMobileNumber": enquirydata.mobile_number,
      "UserEmail": "string",
      "UserState": "string",
      "UserCity": "string",
      "UserAddress": "string",
      "UserPostalCode": "string",
      "UserProductID": "string",
      "UserProductName": enquirydata.interested_in,
      "OTP": 0,
      "OTPGenerateTime": "2022-12-08T11:05:38.366Z",
      "IsOTPVerified": true,
      "UserEnquiryText": "string",
      "IsInvolvementConsentAccepted": true,
      "IsCommunicationConsentAccepted": true,
      "GA_ClientID": "string",
      "API_CountryID": "string",
      "RegionID": "string",
      "RegionName": "string",
      "LanguageCode": "string",
      "IsDeleted": true,
      "ToBeDeleted": true,
      "CreatedDate": "2022-12-08T11:05:38.366Z",
      "ModifiedDate": "2022-12-08T11:05:38.366Z"
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://cors.latlong.in/https://tvsmazlatamappstg02.azurewebsites.net/api/enquiry/addenquiry',
      headers: {
        'Content-Type': 'application/json',
        'region': 'latam',
        'countryid': '8',
        'lang': 'en-US',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        setSubmitStatus(true);
      })
      .catch((error) => {
        setSubmitStatus(false);
      });
  }

  const [value, setValue] = useState()
  const [token, setToken] = React.useState('');
  const [name, setName] = React.useState('');
  const [countryCode, setCountryCode] = React.useState('');
  const [mobileNumber, setMobileNumber] = React.useState('');
  const [interestedIn, setInterestedIn] = React.useState('');
  const [vehicle, setVehicle] = React.useState([]);
  let dealerList = props.dealerList;
  let two_letter_country_code = props.two_letter_country_code;
  let language = props.language;
  const [openSubmit, setOpenSubmit] = React.useState(false);

  const handleClickSubmit = () => {
    setOpenSubmit(true);
  };

  const handleCloseSubmit = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSubmit(false);
  };

  const handleCountryCode = (event) => {
    setCountryCode(event.target.value);
  };

  const handleInerestedIn = (event) => {
    setInterestedIn(event.target.value);
  }

  useEffect(() => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: 'https://stagingtvsapi.latlong.in/get_vehicle_list/PE/2W%20Distributor',
      headers: {}
    };

    axios.request(config)
      .then((response) => {
        setVehicle(response.data.data);
      })
      .catch((error) => {
        // console.log(error);
      });

    let config1 = {
      method: 'get',
      maxBodyLength: Infinity,
      url: 'https://stagingtvsapi.latlong.in/get_token_for_send_enquiry',
      headers: {}
    };

    axios.request(config1)
      .then((response) => {
        setToken(response.data.data.access_token);
      })
      .catch((error) => {
        // console.log(error);
      });

  }, [])

  const handleValidate = (value) => {
    const isValid = isValidPhoneNumber(value);
    return isValid
  }

  return (
    <>
      <ContactFormInfo>
        {language.ContactHeading}
      </ContactFormInfo>
      <form onSubmit={handleSubmit(handleEnquiry)}>
        <LineItemDiv>
          <FormLabelDiv>
            <StyledInputLabel>{language.Name}*</StyledInputLabel>
          </FormLabelDiv>
          <FormInputDiv>
            <Input name="name" {...register('name')} required style={{ width: 'inherit' }}></Input>
          </FormInputDiv>
        </LineItemDiv>
        <LineItemDiv>
          <FormLabelDiv>
            <StyledInputLabel>{language.MobileNumber}*</StyledInputLabel>
          </FormLabelDiv>
          <FormInputDiv>
            <StyledPhoneInput
              defaultCountry={two_letter_country_code}
              flags={flags}
              name="mobile_number"
              {...register('mobile_number')}
              value={value}
              onChange={setValue}
              required rules={{
                validate: (value) => handleValidate(value)
              }}
            />
          </FormInputDiv>
        </LineItemDiv>
        <LineItemDiv>
          <FormLabelDiv>
            <StyledInputLabel>{language.InterestedIn}</StyledInputLabel>
          </FormLabelDiv>
          <FormInputDiv>
            <Select
              id="interested_in"
              name="interested_in"
              {...register("interested_in")}
              value={interestedIn}
              onChange={handleInerestedIn}
              label="Interested in"
              variant="standard"
              style={{ width: 'inherit', fontSize: '14px' }}
            >
              <MenuItem value={`${language.Accesories}`}>{language.Accesories}</MenuItem>
              <MenuItem value={`${language.SpareParts}`}>{language.SpareParts}</MenuItem>
              {vehicle && vehicle.map((item) => {
                return (
                  <MenuItem value={item}>{item}</MenuItem>
                )
              })
              }
            </Select>
          </FormInputDiv>
        </LineItemDiv>
        <LineItemDiv style={{ justifyContent: 'center', marginTop: '16px' }}>
          <Button fontSize="small" type="submit" variant="outlined" style={{ height: '30px' }} onClick={handleClickSubmit}>{language.Submit}</Button>
          <Snackbar open={openSubmit} autoHideDuration={6000} onClose={handleCloseSubmit}>
            {
              submitStatus ?
                <Alert onClose={handleCloseSubmit} severity="success" sx={{ width: '100%' }}>
                  {language.SubmitSuccess}
                </Alert>
                :
                <Alert onClose={handleCloseSubmit} severity="error" sx={{ width: '100%' }}>
                  {language.SubmitError}
                </Alert>
            }
          </Snackbar>
        </LineItemDiv>
      </form>
    </>

  );
}

export default DealerContactForm;